<template>
    <div class="packagePrice">
        <a-modal :title="extrabeds.title + ' - nová prístelka'" centered  v-model="setupVisible" v-if="setupVisible" @ok="handleOk">
            <div class="additional-setup">
                <div class="mb-2">
                    <label class="form-control-label">Názov položky</label>
                    <a-input alternative="" input-classes="form-control-alternative" v-model="newExtra.title"/>
                </div>
                <div class="mb-2">
                    <label class="form-control-label">Priradenie</label>
                    <a-select style="width: 100%" v-model="newExtra.assignment" placeholder="Vyberte si zo zoznamu">
                        <a-select-option v-for="(op, indd) in types" :key="op.value">{{op.label}}</a-select-option>
                    </a-select>
                </div>
                <div class="mb-2">
                    <label class="form-control-label">Nocí</label>
                    <a-select style="width: 100%"
                              v-model="newExtra.nights"
                              placeholder="Vyberte si zo zoznamu">
                        <a-select-option :key="0">Všetky noci</a-select-option>
                        <a-select-option  v-for="op in fieldData.numbernights" :key="op">{{options['numbernights'][op]}}</a-select-option>
                    </a-select>
                </div>
                <div v-if="newExtra.assignment > 0">
                    <div class="mb-2">
                        <label class="form-control-label">Vek od</label>
                        <a-select style="width: 100%"
                                  v-model="newExtra.agefrom"
                                  placeholder="Vyberte si zo zoznamu">
                            <a-select-option :key="0">0</a-select-option>
                            <a-select-option v-for="op in 99" :key="op">{{op}}</a-select-option>
                        </a-select>
                    </div>

                    <div class="mb-2">
                        <label class="form-control-label">Vek do</label>
                        <a-select style="width: 100%"
                                  v-model="newExtra.ageto"
                                  placeholder="Vyberte si zo zoznamu">
                            <a-select-option v-for="op in 99" :key="op">{{op}}</a-select-option>
                        </a-select>
                    </div>
                </div>
                <div class="mb-2">
                    <label class="form-control-label">Cena</label>
                    <a-input-number :step="0.1" v-model="newExtra.price" style="width: 100%" />
                </div>
                <div class="mb-2">
                    <label class="form-control-label">Cena strava</label>
                    <a-input-number :step="0.1" v-model="newExtra.foodprice" style="width: 100%" />
                </div>
                <dicv class="mb-2">
                    <label class="form-control-label">Poznámka</label>
                    <a-input alternative="" input-classes="form-control-alternative" v-model="newExtra.note"/>
                </dicv>
            </div>
        </a-modal>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            
            <div class="container-fluid d-flex align-items-center">
                <div class="col-lg-9 p-0 col-md-10">
                </div>
            </div>
        </div>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-7 col-md-7 col-lg-7  order-xl-1">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-5">
                                    <h5 class="mb-10 text-gray"><span v-if="!isNaN(id)">Naceňujete <span class="text-lowercase">{{content.ctt}}</span></span></h5>
                                    <h2 class="mb-0 page-title">{{fieldData['title']}}</h2>
                                </div>
                                <div class="col-7 text-right">
                                    <div v-if="fieldData.room && fieldData.numbernights">
                                        <router-link :to="'/edit-content/'+id" class="ml-2 float-right">
                                            <template>
                                                <a-button type="primary" >Nastavenia</a-button>
                                            </template>
                                        </router-link>
                                    </div>
                                    <a v-if="fieldData.url" :href="vars.site_url + '/' + fieldData.language + '/' + fieldData.url" target="_blank" class="btn btn-sm btn-outline-primary float-right">Zobraziť na webe</a>
                                </div>
                            </div>
                        </div>
                        <template>
                            <div class="overflow-fix">
                                <div class="filter-panel">
                                    <div v-if="fieldData.room && fieldData.numbernights" class="form-group" style="border: 1px solid rgb(255 210 210);    border-radius: 10px;    display: block;    float: left;    padding: 3px 10px;    background-color: rgb(255 239 239);">
                                        <label style="font-weight:bold;margin-bottom:0;line-height:30px;margin-right:20px;">Percentálna zľava</label>
                                        <a-input-number :step="0.1" :min="0" :max="99" style="float:right" @change="addDiscount" class="form-compact" v-model="percentDrop"/></div>
                                    <a-button class="btn-outline-primary" type="primary" @click="loadRoomPrices" >Nahrať aktuálne ceny izieb</a-button>
                                    <div v-if="fieldData.room && fieldData.numbernights" class="form-group" style="border:1px solid rgb(220 220 220);border-radius:10px;display:block;float:right;padding:3px 10px;"><label style="font-size:12px;margin-right:20px;">Bežné dni</label><a-switch default-unchecked @change="toggleWeekends" /><label style="font-size:12px;margin-left:20px;">Víkendy</label></div></div>
                                <table class="sticky-header" v-if="options['numbernights'] && fieldData">
                                    <tbody>
                                    <tr v-for="room in fieldData.room">
                                        <td>
                                            <h3 class="room-title">{{options['room'][room].title}} <span v-if="showweekends">Víkend</span>
                                                <a-button v-if="!showweekends" class="extrabed-button" @click="openModal(room)" icon="layout">Prístelky</a-button>
                                                <a-button class="autofill"  @click="autoFill(room)" style="float:right" icon="table">Prepočítať</a-button>
                                            </h3>
                                            <table style="valign:middle;text-align:center" class="room-table mb-4"><thead><tr><th></th>
                                                <th v-for="nn in fieldData.numbernights">{{options['numbernights'][nn]}}</th></tr></thead>
                                                <tbody>
                                                <tr v-for="adults in parseInt(options['room'][room].beds)" v-if="adults >= rooms[room].min">
                                                    <th style=""> {{adults}}x osoba</th>
                                                    <td v-for="nn in fieldData.numbernights">
                                                        <div class="form-item form-type-textfield" v-if="showweekends">
                                                            <a-input alternative="" :key="room + '_a' + adults + '_s_'+parseInt(options['numbernights'][nn])" input-classes="form-control-alternative" v-model="prices[room][adults][parseInt(options['numbernights'][nn])]['w']"/>
                                                        </div>
                                                        <div class="form-item form-type-textfield" v-else>
                                                            <a-input alternative="" :key="room + '_a' + adults + '_s_'+parseInt(options['numbernights'][nn])" input-classes="form-control-alternative" v-model="prices[room][adults][parseInt(options['numbernights'][nn])]"/>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div class="text-right">
                                <button class="btn btn-primary" @click="sendPrices">Uložiť</button>
                            </div>
                        </template>

                    </a-card>

                </div>


                <div class="col-xl-5 col-md-5 col-lg-5  order-xl-1 params">
                    <div> <a-card >
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h5 class="mb-0 text-gray"><span v-if="!isNaN(id)">Cenník <span class="text-lowercase">{{fieldData['title']}}</span></span></h5>
                                </div>
                                <div class="col-4 text-right">

                                </div>
                            </div>
                        </div>
                        <template>
                            <div class="overflow-fix">
                                <table style="width:100%" v-if="options['numbernights'] && fieldData">
                                    <tbody>
                                    <tr v-for="(room,index) in fieldData.room" :key="'romp'+index">
                                        <td style="width:100%;font-size:14px;">
                                            <table style="width:100%;valign:middle;text-align:left" class="room-table">
                                                <thead v-if="index === 0"><tr><th class="">Izba</th>
                                                    <th v-for="nn in fieldData.numbernights">{{options['numbernights'][nn]}}</th></tr></thead>
                                                <tbody>
                                                <tr v-for="adults in parseInt(rooms[room].beds)" v-if="prices[room] && adults >= rooms[room].min">
                                                    <th>{{options['room'][room].title}} {{options['room'][room].min}} ({{adults}} os)</th>
                                                    <td v-for="nn in fieldData.numbernights">
                                                    <span v-if="prices[room][adults][parseInt(options['numbernights'][nn])]"
                                                          :title="'Služby: ' + (recalcServicePrice(parseFloat(prices[room][adults][parseInt(options['numbernights'][nn])]),parseInt(options['numbernights'][nn]),parseInt(adults)) - parseFloat(prices[room][adults][parseInt(options['numbernights'][nn])]))+ ' €'">
<span v-if="percentDrop > 0">{{roundUp(recalcServicePrice(parseFloat(prices[room][adults][parseInt(options['numbernights'][nn])] * (1 - (percentDrop / 100))), parseInt(options['numbernights'][nn]), parseInt(adults)),0) }} €</span>
<span v-else>{{recalcServicePrice(parseFloat(prices[room][adults][parseInt(options['numbernights'][nn])]), parseInt(options['numbernights'][nn]), parseInt(adults))}} €</span>
                                                    </span>
                                                    </td>
                                                    <td style="width:25px;"></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </template>

                    </a-card>
                    </div>
                    <div class="mt-4"  v-if="extraextra[0]"> <a-card>
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h5 class="mb-0 text-gray"><span v-if="!isNaN(id)">Cenník prísteliek <span class="text-lowercase">{{fieldData['title']}}</span></span></h5>
                                </div>
                                <div class="col-4 text-right">

                                </div>
                            </div>
                        </div>
                        <template>
                            <div class="overflow-fix">
                                <table v-if="extraextra" style="width:100%;valign:middle;text-align:left" class="room-table">
                                    <thead><tr><th></th>
                                        <th v-for="nn in fieldData.numbernights">{{options['numbernights'][nn]}}</th></tr></thead>
                                    <tbody>
                                    <tr v-for="extra in extraextra">
                                        <th v-html="extra[0]"></th>
                                        <td v-for="coo in extra[1]" :colspan="coo.colspan" :style="coo.style" v-html="coo.data"></td>
                                        <td style="width:25px;">

                                            <a-popconfirm placement="topRight"
                                                          @confirm="removeExtra(extra[1][0].id)"
                                                          okText="Áno"
                                                          cancelText="Nie">
                                                <template slot="title">
                                                    <h5 color="danger" >Prajete si túto službu odstrániť?</h5>
                                                    Položka okamžite ovplyvní aj ceny zvýhodneného pobytu na webe.
                                                </template>
                                                <a-button  type="link" style="padding:0;color:orangered" icon="delete" ghost></a-button>
                                            </a-popconfirm>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <base-alert type="warning" v-else :dismissible = "true">Prístelky ešte neboli určené</base-alert>
                            </div>

                        </template>

                    </a-card>
                    </div>
                </div>

            </div>
        </div>


        <div class="container-fluid mt-5">
            <div class="row">
                <div class="col-xl-12 col-md-12 col-lg-12 order-xl-1">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h5 class="mb-0 text-gray">Služby vo zvýhodnenom pobyte</h5>
                                </div>
                                <div class="col-4 text-right">
                                    <a-button @click="openService" type="primary" icon="plus"> Pridať službu </a-button>
                                </div>
                            </div>
                        </div>
                        <template>
                            <div class="overflow-fix">
                                <table style="width:100%" class="services-table" v-if="options['numbernights'] && fieldData">
                                    <thead>
                                    <tr>
                                        <th>Služba</th>
                                        <th>Poznámka</th>
                                        <th>Typ</th>
                                        <th>Cena</th>
                                        <th>Násobok</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="services in additions">
                                        <td><span v-if="services.count > 1">{{services.count}}x </span>{{services.title}} </td>
                                        <td>{{services.note}}</td>
                                        <td><span v-for="ti in choices"><span v-if="ti.value === parseInt(services.type)">{{ti.label}}</span></span> </td>
                                        <td><span v-if="services.price !== '0' && services.price !== 0"><span v-if="services.count > 1">{{services.count}}x </span>{{services.price}} €</span><span v-else>Zadarmo</span></td>
                                        <td><span v-for="ti in times"><span v-if="services.times.includes(parseInt(ti.value))"> {{ti.label}}</span></span> </td>
                                        <td>
                                            <a-button type="link" style="color:#6373e7" @click="editService(services)" icon="edit" ghost></a-button>
                                            <a-popconfirm placement="topRight"
                                                          @confirm="removeService(services.id)"
                                                          okText="Áno"
                                                          cancelText="Nie">
                                                <template slot="title">
                                                    <h5 color="danger" >Prajete si túto službu odstrániť?</h5>
                                                    Položka okamžite ovplyvní aj ceny zvýhodneného pobytu na webe.
                                                </template>
                                                <a-button   type="link" style="padding:0;color:orangered" icon="delete" ghost></a-button>
                                            </a-popconfirm>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </template>

                    </a-card>

                </div>
                <a-modal title="Služba k zvýhodnenému pobytu" centered  v-model="serviceVisible" v-if="serviceVisible" @ok="handleOk">
                    <template>
                        <div class="overflow-fix">
                            <div class="m-1">
                                <label class="form-control-label">Násobok</label>
                                <div style="width:100%;display:inline-block;" class="voucher-services">
                                    <a-input-number style="width: 100%;" alternative="" :min="1" input-classes="form-control-alternative" v-model="newService.count"/>
                                </div>
                            </div>
                            <a-input type="hidden" v-model="newService.id" />
                            <div class="m-1">
                                <label class="form-control-label">Vlastná položka</label>
                                <div style="width:100%;display:inline-block;" class="voucher-services">
                                    <a-input  style="width: 100%;" alternative="" :min="1"  input-classes="form-control-alternative" v-model="newService.text"/>
                                </div>
                            </div>
                            <div class="m-1">
                                <label class="form-control-label">Poznámka</label>
                                <div style="width:100%;display:inline-block;" class="voucher-services">
                                    <a-input  style="width: 100%;" alternative="" :min="1"  input-classes="form-control-alternative" v-model="newService.note"/>
                                </div>
                            </div>
                            <div class="m-1">
                                <label class="form-control-label">Služba</label>
                                <div style="width:100%;display:inline-block;" class="voucher-services">
                                    <a-select style="width: 100%;" placeholder="Služba z taxonómie služieb" default-value="0" v-model="newService.tid">
                                        <a-select-option :value = "0">Žiadna služba</a-select-option>
                                        <a-select-option v-for="pa in services" :value = "pa.value">{{pa.label}}</a-select-option>
                                    </a-select>
                                </div>
                            </div>
                            <div class="m-1">
                                <label class="form-control-label">Masáž</label>
                                <div style="width:100%;display:inline-block;" class="voucher-services">
                                    <a-select style="width: 100%;" placeholder="Masáž z obsahu" v-model="newService.massage">
                                        <a-select-option :value = "0">Žiadna masáž</a-select-option>
                                        <a-select-option v-for="pa in massages" :value = "pa.value">{{pa.label}}</a-select-option>
                                    </a-select>
                                </div>
                            </div>

                            <div class="m-1">
                                <label class="form-control-label">Možnosti</label>
                                <div style="width:100%;display:inline-block;" class="voucher-services">
                                    <a-select style="width: 100%;" placeholder="Spôsob zarátavania" :default-value="newService.type"  v-model="newService.type">
                                        <a-select-option v-for="pa in choices" :value = "pa.value">{{pa.label}}</a-select-option>
                                    </a-select>
                                </div>
                            </div>
                            <div class="m-1">
                                <label class="form-control-label">Násobiteľ</label>
                                <div style="width:100%;display:inline-block;" class="voucher-services">
                                    <a-select style="width: 100%;" mode="multiple"  placeholder="Násobiteľ ceny" v-model="newService.times">
                                        <a-select-option v-for="pa in times" :value = "pa.value">{{pa.label}}</a-select-option>
                                    </a-select>
                                </div>
                            </div>
                            <div class="m-1">
                                <label class="form-control-label">Cena</label>
                                <div style="width:100%;display:inline-block;" class="voucher-services">
                                    <a-input-number style="width: 100%;" alternative="" :min="0"  input-classes="form-control-alternative" v-model="newService.price"/>
                                </div>
                            </div>

                            <div class="m-1">
                                <label class="form-control-label">Promo</label>
                                <div style="width:100%;display:inline-block;" class="voucher-services">
                                    <a-checkbox class="mb-3" v-model="newService.promo"/>
                                </div>
                            </div>
                            <div class="m-1">
                                <label class="form-control-label">Zadarmo</label>
                                <div style="width:100%;display:inline-block;" class="voucher-services">
                                    <a-checkbox class="mb-3" v-model="newService.free"/>
                                </div>
                            </div>


                        </div>
                    </template>
                    <template slot="footer">
                        <div class="mb-2">
                            <div style="width:100%;display:inline-block;" class="voucher-services">
                                <a-button key="cancel"  @click="handleCancel">Zrušiť</a-button>
                                <a-button @click="addService" key="edit" icon="edit" v-if="newService.id" type="primary">Upraviť službu</a-button>
                                <a-button @click="addService" key="add" icon="plus" v-else type="primary">Pridať službu</a-button>
                            </div></div>
                    </template>
                </a-modal>


            </div>
        </div>
    </div>
</template>

<script>

    import {API_URL} from "../../api";
    import {authHeader} from "../../_helpers";
    import {dataService} from "../../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import ADatePicker from "ant-design-vue/es/date-picker";
    import moment from 'moment'
    import 'moment/locale/sk';
    import ARadioGroup from "ant-design-vue/es/radio/Group";
    import ARadioButton from "ant-design-vue/es/radio/RadioButton";
    import "./_packagePrice.scss";

    Vue.use(FormItem);


    export default {
        name: 'editcontent',
        data() {
            return {
                fieldData:{},
                content:{},
                options:{},
                rooms:{},
                prices:{},
                vars:{},
                percent:0,
                newService:{},
                newExtra:{},
                percentDrop: 0,
                roomprices:{},
                additions:{},
                extraextra:{},
                percentageDrop:{},
                serviceVisible: false,
                massages:{},
                services:{},
                colorsto: JSON.parse(localStorage.colors),
                dailyaddition:0,
                overtwo:0,
                pernight:0,
                showweekends:false,
                perperson:0,
                extrabeds:{},
                types: [
                    {"value": 0, "label": 'dospelý'},
                    {"value": 1, "label": '1. dieťa'},
                    {"value": 2, "label": '2. dieťa'}
                ],
                times: [
                    {"value": 0, "label": 'na balíček'},
                    {"value": 1, "label": 'x počet nocí'},
                    {"value": 2, "label": 'x počet osôb'}
                ],
                choices: [
                    {"value": 0, "label": 'Vrátane balíčka'},
                    {"value": 1, "label": 'Voliteľná služba'},
                    {"value": 2, "label": 'Voliteľná služba nad 2 osoby'}
                ],
                setupVisible:false

            }
        },
        props: ['id'],
        methods: {
            toggleWeekends(e)
            {
                if(e === true)
                {
                    this.showweekends = true;
                }
                else
                {
                    this.showweekends = false;
                }
            },
            addDiscount(e)
            {

                for (const [room, fo] of Object.entries(this.prices)) {
                    for (const [people, prices] of Object.entries(this.prices[room])) {
                        for (const [nights, price] of Object.entries(prices)) {
                            this.recalcServicePrice(price,nights,people);
                        }
                    }
                }
                    },
            openModal(r)
            {
                this.extrabeds = this.options["room"][r]
                this.newExtra.room = r
                this.newExtra.package = this.id
                this.setupVisible = true
            },
            handleCancel(e) {
                this.serviceVisible = false;
            },
            roundUp(num, precision) {
                precision = Math.pow(10, precision)
                return Math.ceil(num * precision) / precision
            },
            handleOk()
            {
                if(this.newExtra.nights>0)
                    this.newExtra.nights = this.options["numbernights"][this.newExtra.nights];
                dataService.axiosPost("package/extrabeds", this.newExtra).then(results => {
                    if (results.data.success) {
                        this.$message.success('Prítelka bola pridaná');
                        this.fetch(); this.setupVisible = false;
                    } else {
                        this.$message.error('Pri ukladaní nastala chyba');
                    }
                });
            },
            sendPrices()
            {
            dataService.axiosPost("package/"+ this.id, {"packageprices" : this.prices, "percent": this.percentDrop}).then(results => {});
            },
            openService()
            {
                this.serviceVisible = true;
                this.newService = {};
            },
            editService(e)
            {

                e.type = parseInt(e.type);
                if(e.promo === "0")
                    e.promo = false;
                else
                    e.promo = true;
                if(e.free === "0")
                    e.free = false;
                else
                    e.free = true;
                this.newService = e;
                this.serviceVisible = true;

            },
            loadRoomPrices()
            {

                console.log(this.roomprices);

            },
            addService()
            {
                this.newService.package = this.id
                if(!this.newService.price)
                    this.newService.price = 0;
                dataService.axiosPost("package/addservice", this.newService).then(results => {
                    if (results.data.success) {
                        if(results.data.message === "EditOK")
                            this.$message.success('Služba bola upravená');
                        else
                            this.$message.success('Služba bola pridaná');
                        this.fetch();
                        this.setupVisible = false;
                        this.serviceVisible = false;
                    } else {
                        this.$message.error('Pri ukladaní nastala chyba');
                    }
                });
            },
            autoFill: function (room) {

                let percentageDrop = parseFloat(this.percentageDrop[this.content.rooms[room].category]);
                let firstNight = parseInt(this.options['numbernights'][parseInt(this.fieldData['numbernights'][0])]);
                let base_price = 0;
                let base_price_up = base_price;

                if(this.prices[room][1][firstNight] > 0)
                {
                    base_price = parseFloat(this.prices[room][1][firstNight] / firstNight);
                    console.log(base_price)

                    base_price_up = base_price;
                    if (percentageDrop > 0)
                        base_price_up = base_price * parseFloat(1 + (percentageDrop/100));
                    if (percentageDrop < 0)
                        base_price_up = base_price * parseFloat(1 + (percentageDrop/100));

                }
                else
                {
                    base_price = parseFloat(this.prices[room][2][firstNight] / firstNight)/2;
                    base_price_up = base_price;

                }

                let start_from = 3;

                let dioprice = 0;
                for (const [people, prices] of Object.entries(this.prices[room])) {
                    for (const [nights, price] of Object.entries(prices)) {
                        let madeprice = 0;
                        if (base_price !== base_price_up && people === '1')
                            madeprice = Math.floor((parseInt(nights) * parseInt(people)) * base_price)
                        else
                            madeprice = Math.floor((parseInt(nights) * parseInt(people)) * base_price_up)
                        let pe = 1;
                        if(parseInt(nights) < 6)
                            pe = parseFloat(this.percent) + (parseFloat(parseInt(nights) / 10)  * 2.55)
                        else
                            pe = parseFloat(this.percent) + parseFloat((parseInt(nights) / 10) * 2.35);

                        let perprice = parseFloat((madeprice / 100) * pe);
                        if (start_from < nights)
                            dioprice = Math.floor(madeprice - perprice);
                        else
                            dioprice = madeprice;
                        this.prices[room][people][nights] = dioprice;
                    }
                }
            },
            removeExtra(e)
            {
                dataService.axiosPost("package/removeextra", e).then(results => {
                    if (results.data.success) {
                        this.$message.success('Služba bola odstránená');
                        this.fetch();
                        this.setupVisible = false;
                    } else {
                        this.$message.error('Pri ukladaní nastala chyba');
                    }
                });
            },
            removeService(e)
            {
                dataService.axiosPost("package/removeservice", e).then(results => {
                    if (results.data.success) {
                        this.$message.success('Služba bola odstránená');
                        this.fetch();
                        this.setupVisible = false;
                    } else {
                        this.$message.error('Pri ukladaní nastala chyba');
                    }
                });
            },
            recalcServicePrice(price,nights,adults)
            {

                var add = 0;
                this.additions.forEach(obj => {
                    if(obj.type === '0')
                    {
                        if(!obj.count)
                            obj.count = 1;
                        if(obj.times.includes(1) && obj.times.includes(2))
                        {
                            add =  add + (parseInt(nights) * (parseInt(adults) * parseFloat(obj.price * obj.count)));
                        }
                        if(obj.times.includes(0) && !obj.times.includes(1) && !obj.times.includes(2))
                        {
                            add =  add + parseFloat(obj.price * obj.count);
                        }

                        if(obj.times.includes(1) && !obj.times.includes(0) && !obj.times.includes(2))
                        {
                            add =  add + (parseInt(nights) * parseFloat(obj.price * obj.count));
                        }

                        if(obj.times.includes(2) && !obj.times.includes(1) && !obj.times.includes(0))
                        {
                            add =  add + (parseInt(adults) * parseFloat(obj.price * obj.count));
                        }

                    }
                })



                return this.roundUp(price + add,0)
            },
            fetch()
            {
                dataService.axiosFetch("package", this.id).then(results => {
                    this.fieldData = results.data
                    this.content = results
                    this.options = results.optionsNew
                    this.prices = results.prices
                    this.percentDrop = results.data.percent
                    this.extraextra = results.thef
                    this.rooms = results.rooms
                    this.percent = results.percent
                    this.massages = results.massages
                    this.services = results.s
                    this.percentageDrop = results.roomtypes
                    this.additions = results.additions
                    this.vars = results.vars
                });
            }
        },
        mounted() {
            if (!isNaN(this.id)) {
                this.fetch();
            }
            dataService.axiosFetch('getroomprices').then(results => {

                let roomprices = results.roomprices;
                let roleops = {};
                roomprices.forEach(obj => { roleops[obj.room + '_' + obj.pricetype] = obj.price; });
                this.roomprices = roleops;
            });
        }
    };
</script>
<style>
.form-compact
{max-height:35px;}
    .packagePrice .filter-panel .form-group
    {background-color:#fff;}
    .packagePrice .filter-panel
    {
        display:inline-block;
        width:calc(100% + 48px);
        margin:-24px;
        padding:10px;
        margin-bottom:25px;
        background-color: rgba(0, 0, 0, 0.007);
        border-bottom:1px solid rgba(0, 0, 0, 0.02);
    }
</style>
